<template>
  <div>
    <h1>Admin</h1>
    <div class="mt-8">
      <button
        v-for="number of pool"
        :key="number"
        :class="{active: active == number}"
        @click="toggle(number)"
      >
        {{ number }}
      </button>
    </div>
  </div>
</template>

<script>
const API = `${window.location.protocol}//${window.location.hostname}`;

export default {
  data: () => ({
    pool: [],
    active: 0,
  }),
  mounted() {
    this.update();
    this.refresh = setInterval(this.update.bind(this), 1000);
  },
  unmounted() {
    clearInterval(this.refresh);
  },
  methods: {
    async update() {
      const data = await this.axios(`${API}/pool`);
      this.pool = data.data.pool;
      this.active = data.data.active;
    },
    async toggle(number) {
      const data = await this.axios.post(`${API}/active`, { active: number });
      this.pool = data.data.pool;
      this.active = data.data.active;
    },
  },
};
</script>

<style lang="scss" scoped>
button {
    width: 3.5rem;
    padding: 10px;
    margin-right: 7px;
    margin-bottom: 7px;

    &.active {
        background: var(--rhino);
        color: white;
    }
}
</style>
